// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://tm5zezibonc4pgzlqwdap37tai.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-kju6owg5nbblxhpwgneqmlyucq",
    "aws_cognito_identity_pool_id": "ap-southeast-1:5df82b94-1858-45e5-a6fd-daee585ee3bb",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_6SrbZKyf1",
    "aws_user_pools_web_client_id": "25l9teo2schhc9ef4lmj91lp5q",
    "oauth": {},
    "aws_content_delivery_bucket": "eop-api-20191009162354-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d31c03sxiy9sq8.cloudfront.net"
};


export default awsmobile;
